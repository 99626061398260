<script>
import {mapGetters} from "vuex";

export default {
    name: "relative-date",

    inheritAttrs: false,

    emits: ['update:modelValue', 'update:time'],

    props: {
        modelValue: {},
        time: {},
        state: {},
        errors: {},
        stateTime: {},
        errorsTime: {}
    },

    computed: {
        ...mapGetters({
            relativeFormats: 'relativeFormat/grouped',
            loading: 'relativeFormat/loading',
        }),

        basics: function () {
            return this.relativeFormats?.basics
        },

        days: function () {
            return this.relativeFormats?.days
        },

        months: function () {
            return this.relativeFormats?.months
        },

        specialDays: function () {
            return this.relativeFormats?.special_days
        },

        selectedBasic: function () {
            return this.basics?.find(basic => basic.id === this.basic)
        },

        showDays: function () {
            return this.selectedBasic?.value.includes('week')
                || this.selectedBasic?.value.includes('month')
                || this.selectedBasic?.value.includes('year')
        },

        showMonths: function () {
            return this.selectedBasic?.value.includes('year')
        },

        showSpecialDays: function () {
           return this.selectedBasic?.value.includes('month')
               || this.selectedBasic?.value.includes('year')
        },

        model: {
            get: function () {
                return this.modelValue
            },

            set: function (value) {
                this.$emit('update:modelValue', value)
            },
        }
    },

    data: function () {
        return {
            date: new Date(),
            basic: null,
            day: null,
            month: null,
            specialDay: null,
            hour: null
        }
    },

    methods: {
        fresh: function () {
            const value = [this.basic, this.day, this.month, this.specialDay].filter(item => item)

            this.$emit('update:modelValue', value)
        },

        init: function () {
            if (this.modelValue && typeof this.modelValue === 'string') {
                this.basics?.find(basic => this.modelValue.includes(basic.value))
                this.days?.find(day => this.modelValue.includes(day.value))
                this.months?.find(month => this.modelValue.includes(month.value))
                this.specialDays?.find(specialDay => this.modelValue.includes(specialDay.value))
            }
            if (Array.isArray(this.modelValue)) {
                this.modelValue.forEach(relativeFormat => {
                    let basic = this.basics?.find(basic => basic.value === relativeFormat.value)
                    let day = this.days?.find(day => day.value === relativeFormat.value)
                    let month = this.months?.find(month => month.value === relativeFormat.value)
                    let spec = this.specialDays?.find(specialDay => specialDay.value === relativeFormat.value)

                    if (basic) {
                        this.basic = basic.id
                    }
                    if (day) {
                        this.day = day.id
                    }
                    if (month) {
                        this.month = month.id
                    }
                    if (spec) {
                        this.specialDay = spec.id
                    }
                })
            }
        },
    },

    watch: {
        modelValue: {
            immediate: true,
            handler: function () {
                this.init()
            }
        },

        time: {
            immediate: true,
            handler: function (value) {
                this.hour = value
            },
        },

        basics: function () {
            this.init()
        },

        days: function () {
            this.init()
        },

        months: function () {
            this.init()
        },

        specialDays: function () {
            this.init()
        },

        basic: function () {
            this.fresh()
        },
        day: function () {
            this.fresh()
        },
        month: function () {
            this.fresh()
        },
        specialDay: function () {
            this.fresh()
        },
        hour: function (value) {
            this.$emit('update:time', value)
        },
    },

    mounted() {
        if (!this.relativeFormats || !this.relativeFormats.length) {
            this.$store.dispatch('relativeFormat/grouped')
        }
    }
}
</script>

<template>
    <div :class="$attrs.class">
        <div class="d-flex">
            <select
                :id="$attrs.id"
                :name="$attrs.name || $attrs.id"
                v-model="basic"
                :class="{'border-danger': state === false}"
                class="form-select me-2"
            >
                <option :value="basic === undefined ? undefined : null">
                    {{ $t('base.please_select').ucFirst() }}
                </option>
                <option v-for="(relativeFormat, index) in basics" :key="index" :value="relativeFormat.id">
                    {{ relativeFormat.translation_key ? $t(relativeFormat.translation_key).ucFirst() : relativeFormat.value }}
                </option>
            </select>
            <select
                v-if="showMonths"
                :id="$attrs.id"
                :name="$attrs.name || $attrs.id"
                v-model="month"
                :class="{'border-danger': state === false}"
                class="form-select me-2"
            >
                <option :value="month === undefined ? undefined : null">
                    {{ $t('base.please_select').ucFirst() }}
                </option>
                <option v-for="(relativeFormat, index) in months" :key="index" :value="relativeFormat.id">
                    {{ relativeFormat.translation_key ? $t(relativeFormat.translation_key).ucFirst() : relativeFormat.value }}
                </option>
            </select>
            <select
                v-if="showDays"
                :id="$attrs.id"
                :name="$attrs.name || $attrs.id"
                v-model="day"
                :class="{'border-danger': state === false}"
                class="form-select me-2"
                :disabled="specialDay !== null"
            >
                <option :value="day === undefined ? undefined : null">
                    {{ $t('base.please_select').ucFirst() }}
                </option>
                <option v-for="(relativeFormat, index) in days" :key="index" :value="relativeFormat.id">
                    {{ relativeFormat.translation_key ? $t(relativeFormat.translation_key).ucFirst() : relativeFormat.value }}
                </option>
            </select>
            <select
                v-if="showSpecialDays"
                :id="$attrs.id"
                :name="$attrs.name || $attrs.id"
                v-model="specialDay"
                :class="{'border-danger': state === false}"
                class="form-select me-2"
                :disabled="day !== null"
            >
                <option :value="specialDay === undefined ? undefined : null">
                    {{ $t('base.please_select').ucFirst() }}
                </option>
                <option v-for="(relativeFormat, index) in specialDays" :key="index" :value="relativeFormat.id">
                    {{ relativeFormat.translation_key ? $t(relativeFormat.translation_key).ucFirst() : relativeFormat.value }}
                </option>
            </select>
            <input
                v-model="hour"
                class="form-control"
                type="time"
                :class="{'border-danger': stateTime === false}"
            />
        </div>
        <div :class="{'d-block': state === false}" class="invalid-feedback">
            {{ errors }} {{ errorsTime }}
        </div>
    </div>
</template>

<style scoped>

</style>
