<script>
import {defineComponent} from 'vue'
import TranslationInput from "../elements/translation-input.vue";
import {mapGetters} from "vuex";
import RelativeDate from "../elements/relative-date.vue";

export default defineComponent({
    name: "reminder-form",

    emits: ['saved', 'updated'],

    props: {
        reminder: {
            type: Object,
            required: true
        },

        remindableType: {
            type: String,
            default: 'App\\Models\\User'
        },

        remindableId: {
            type: Number
        },

        creatorType: {
            type: String,
            default: 'App\\Models\\User'
        },

        creatorId: {
            type: Number
        },

        redirect: {
            type: Boolean,
            default: true
        },

        doNotSave: {
            type: Boolean,
            default: false
        },

        withoutSaveBtn: {
            type: Boolean,
            default: false
        }
    },

    components: {
        RelativeDate,
        TranslationInput
    },

    computed: {
        ...mapGetters({
            loading: 'reminder/loading',
            states: 'reminder/states',
            errors: 'reminder/errors',
            authenticated: 'user/authenticated'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        }
    },

    data: function () {
        return {
            model: {},
            repeating: false
        }
    },

    methods: {

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)

            if (!this.doNotSave) {
                return this.$store.dispatch(`reminder/${method}`, data).then(saved => {
                    this.setModel(saved)
                    this.$emit('saved')
                    if (method === 'create' && this.redirect) {
                        this.$router.push(this.$t('routes./reminders/my-reminders/:id(\\d+)', {id: this.model.id}).rmValidation())
                    }
                })
            } else {
                const copy = JSON.parse(JSON.stringify(this.model))
                this.$store.dispatch('reminder/setItem', copy)
                this.$emit('updated', copy)
            }
        },

        setModel: function (model = {}) {
            const base = {
                remindable_type: this.remindableType,
                creator_type: this.creatorType,
                creator_id: this.creatorId || this.authenticated.id,
                remindable_id: this.remindableId || this.authenticated.id,
                is_active: true
            }

            this.model = Object.assign(base, JSON.parse(JSON.stringify(model)));

            this.repeating = !!this.model?.repeating
        },
    },

    watch: {
        reminder: {
            immediate: true,
            handler: function (value) {
                this.setModel(value)
            }
        },

        model: {
            deep: true,
            handler: function () {
                if (this.withoutSaveBtn && this.doNotSave) {
                    this.save()
                }
            }
        },
    },

    created() {
        this.$store.dispatch('user/all')
    }
})
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row">
                <div class="col-lg-5">
                    <div class="row mb-4">
                        <div class="col-lg-6">
                            <label
                                :class="{'text-danger': states.title === false}"
                                class="form-label"
                                for="title"
                            >{{ $t('reminders::reminders.columns.title').ucFirst() }}</label
                            >
                            <input
                                id="title"
                                v-model="model.title"
                                :class="{'border-danger': states.title === false}"
                                :placeholder="$t('reminders::reminders.placeholders.title').ucFirst()"
                                class="form-control"
                                name="title"
                                type="text"
                            />
                            <div :class="{'d-block': states.title === false}" class="invalid-feedback">{{ errors.title }}</div>
                        </div>

                        <div class="col-lg-6" v-if="false">
                            <label
                                :class="{'text-danger': states.translation_key === false}"
                                class="form-label"
                                for="translation_key"
                            >{{ $t('reminders::reminders.columns.translation_key').ucFirst() }}</label
                            >
                            <translation-input
                                v-model="model.translation_key"
                                :error="errors.translation_key"
                                :placeholder="$t('reminders::reminders.placeholders.translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                                :state="states.translation_key"
                            ></translation-input>
                            <div :class="{'d-block': states.translation_key === false}" class="invalid-feedback">
                                {{ errors.translation_key }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-lg-4">
                            <label
                                class="form-label"
                                for="is-active-input"
                            >{{ $t('reminders::reminders.columns.repeating').ucFirst() }}</label
                            >
                            <div class="form-check form-switch form-switch-md">
                                <input
                                    id="is-active-input"
                                    v-model="repeating"
                                    class="form-check-input"
                                    name="repeating"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                        <div class="col" v-if="repeating">
                            <label
                                :class="{'text-danger': states.repeating === false}"
                                class="form-label"
                                for="repeating-input"
                            >{{ $t('reminders::reminders.columns.repeating').ucFirst() }}</label
                            >
                            <input
                                v-model="model.repeating"
                                class="form-control"
                                :class="{'border-danger': states.repeating === false}"
                                type="time"
                            />
                            <div :class="{'d-block': states.repeating === false}" class="invalid-feedback">
                                {{ errors.repeating }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-lg-3 mb-4">
                            <label
                                :class="{'text-danger': states.recurring === false}"
                                class="form-label"
                                for="is-active-input"
                            >{{ $t('reminders::reminders.columns.recurring').ucFirst() }}</label
                            >
                            <div class="form-check form-switch form-switch-md">
                                <input
                                    id="is-active-input"
                                    v-model="model.recurring"
                                    :class="{'border-danger': states.recurring === false}"
                                    class="form-check-input"
                                    name="recurring"
                                    type="checkbox"
                                />
                            </div>
                            <div :class="{'d-block': states.recurring === false}" class="invalid-feedback">
                                {{ errors.recurring }}
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <div class="row">
                                <div class="col-12 mb-4" v-if="model.recurring">
                                    <label
                                        :class="{'text-danger': states.relative_formats === false || states.time === false}"
                                        class="form-label"
                                        for="interval-input"
                                    >{{ $t('reminders::reminders.columns.recurring').ucFirst() }}</label
                                    >
                                    <relative-date
                                        name="interval"
                                        id="interval-input"
                                        v-model="model.relative_formats"
                                        :time="model.time"
                                        @update:time="(value) => model.time = value"
                                        :state="states.relative_formats"
                                        :state-time="states.time"
                                        :errors="errors.relative_formats"
                                        :errors-time="errors.time"
                                    ></relative-date>
                                </div>
                                <div class="col-12 mb-4" v-if="model.recurring">
                                    <label
                                        :class="{'text-danger': states.start === false}"
                                        class="form-label"
                                        for="start"
                                    >{{ $t('reminders::reminders.columns.start').ucFirst() }}</label
                                    >
                                    <input
                                        id="start"
                                        v-model="model.start"
                                        :class="{'border-danger': states.start === false}"
                                        :placeholder="$t('reminders::reminders.placeholders.start').ucFirst()"
                                        class="form-control"
                                        type="datetime-local"
                                    />
                                    <div :class="{'d-block': states.start === false}" class="invalid-feedback">
                                        {{ errors.start }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" v-if="!model.recurring">
                            <label
                                :class="{'text-danger': states.date === false}"
                                class="form-label"
                                for="date"
                            >{{ $t('reminders::reminders.columns.date').ucFirst() }}</label
                            >
                            <input
                                id="date"
                                v-model="model.date"
                                :class="{'border-danger': states.date === false}"
                                :placeholder="$t('reminders::reminders.placeholders.date').ucFirst()"
                                class="form-control"
                                name="date"
                                type="datetime-local"
                            />
                            <div :class="{'d-block': states.date === false}" class="invalid-feedback">{{ errors.date }}</div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-lg-4">
                            <label
                                :class="{'text-danger': states.is_active === false}"
                                class="form-label"
                                for="is-active-input"
                            >{{ $t('reminders::reminders.columns.is_active').ucFirst() }}</label
                            >
                            <div class="form-check form-switch form-switch-md">
                                <input
                                    id="is-active-input"
                                    v-model="model.is_active"
                                    :class="{'border-danger': states.is_active === false}"
                                    class="form-check-input"
                                    name="is_active"
                                    type="checkbox"
                                />
                            </div>
                            <div :class="{'d-block': states.is_active === false}" class="invalid-feedback">
                                {{ errors.is_active }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="row mb-4">
                        <div class="col">
                            <label
                                :class="{'text-danger': states.message === false}"
                                class="form-label"
                                for="message"
                            >{{ $t('reminders::reminders.columns.message').ucFirst() }}</label
                            >
                            <textarea
                                id="message"
                                v-model="model.message"
                                :class="{'border-danger': states.message === false}"
                                :placeholder="$t('reminders::reminders.placeholders.message').ucFirst()"
                                class="form-control"
                                name="message"
                                rows="10"
                            />
                            <div :class="{'d-block': states.message === false}" class="invalid-feedback">{{ errors.message }}</div>
                        </div>
                    </div>

                    <div v-if="false" class="row mb-4">
                        <div class="col">
                            <label
                                :class="{'text-danger': states.message_translation_key === false}"
                                class="form-label"
                                for="message_translation_key"
                            >{{ $t('reminders::reminders.columns.message_translation_key').ucFirst() }}</label
                            >
                            <translation-input
                                v-model="model.message_translation_key"
                                :error="errors.message_translation_key"
                                :placeholder="$t('reminders::reminders.placeholders.message_translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                                :state="states.message_translation_key"
                            ></translation-input>
                            <div :class="{'d-block': states.message_translation_key === false}" class="invalid-feedback">
                                {{ errors.message_translation_key }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button v-if="!withoutSaveBtn" class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
